<template>
  <div class="tc-evaluate-exportUserInfo">
    <!-- 头部工具栏 -->
    <div class="tool">
      <ul>
        <li>
          <img src="@/assets/images/refreshBtn.png" @click="refresh()" alt />
        </li>
        <li>
          <img src="@/assets/images/retreatBtn.png" @click="back()" alt />
        </li>
      </ul>
    </div>
    <!-- 内容 -->
    <el-form ref="form" :model="form" label-width="120px" size="mini">
      <el-form-item label="按科室">
        <el-select v-model="form.department_id" placeholder>
          <el-option
            v-for="item in departmentList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
            >{{ item.name }}</el-option
          >
        </el-select>
      </el-form-item>
      <el-form-item label="按性别导出">
        <el-select v-model="form.sex" placeholder>
          <el-option label="所有" value=""></el-option>
          <el-option label="男" :value="1"></el-option>
          <el-option label="女" :value="0"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="出生范围">
        <el-date-picker
          v-model="value1"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          value-format="yyyy-MM-dd"
        ></el-date-picker>
      </el-form-item>
      <el-form-item label="门诊号/住院号">
        <el-input v-model="form.acc"></el-input>
      </el-form-item>
      <el-form-item label="用户姓名">
        <el-input v-model="form.name"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="exportBtn">立即导出</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  inject: ["reload"],
  data() {
    return {
      form: { department_id: "", sex: "", acc: "", name: "" },
      value1: [],
      departmentList: [],
    };
  },
  created() {
    this.departmentList = JSON.parse(localStorage.getItem("departmentList"));
    this.departmentList.unshift({ name: "全部", id: "" });
  },
  methods: {
    exportBtn() {
      //  department_id  sex   begin_time   end_time
      let time = this.value1.join(",");
      let { department_id, sex, acc, name } = this.form;
      window.location.href = `/export_user?department_id=${department_id}&real_name=${name}&account=${acc}&age=${time}&sex=${sex}`;
    },
    // 刷新
    refresh() {
      this.reload();
    },
    // 返回
    back() {
      this.$router.go(-1); //返回上一层
    },
  },
};
</script>

<style lang="less">
.tc-evaluate-exportUserInfo {
  position: relative;
  margin-top: 30px;
  // 头部工具
  .tool {
    position: absolute;
    top: -80px;
    right: 0;
    ul {
      li {
        cursor: pointer;
        list-style: none;
        float: left;
        margin-left: 10px;
        img {
          width: 100px;
        }
      }
    }
  }
  .el-input__inner {
    width: 200px;
  }
}
</style>